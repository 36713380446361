

import React, { useEffect, useState } from 'react';
import "./style.css";
import SideBar from './../../components/SideBar/SideBar';
import Header from '../../components/Header/Header';
import { FlagIcon, Notification, NotificationIcon } from '../../assets/svgicons';
import { useLocation, useNavigate } from 'react-router-dom';

const DefaultLayout = ({children}) => {

  const [openSide , setOpenSide] = useState(true)
  
  const [sideWidth , setSideWidth] = useState(230);


  const navigate = useNavigate();

  const location = useLocation();



 useEffect(()=>{

  if(openSide){
    setSideWidth(230);
  }else{
    setSideWidth(80);

  }
  

 },[openSide])

  return (
    <div>
     <div className='app_container' style={{
    // border:'1px solid red',
    width:`calc(100% - ${sideWidth }px)`,
    margin:`${20}px`,
    marginLeft:`${sideWidth}px`,
    paddingInline:"40px"

     }}>

      <Header />
        {children}
      </div> 

    <SideBar
      width={sideWidth}
      title={ openSide ? <span className='fw-bolder fs-4'>Marked Places</span> : <spna className= "fw-bolder fs-4" >M</spna> }
      open={openSide}
      onClose={()=>setOpenSide(!openSide)}
    >
       {
        [
          {
            name:"Countries",
            pathname:'/',
            icon:FlagIcon
          },
          {
            name:"Notifications",
            pathname:'/notifications',
            icon:NotificationIcon
          },
        ].map((page , index)=>{
          return(
            <SideBar.Button open={openSide} icon={page.icon} active={location?.pathname == page?.pathname}  activeStyles={{color:"#FFF"}} onClick={()=> navigate(page.pathname)} >
              {page.name}
            </SideBar.Button>
          )
        })
      } 
    </SideBar>
    </div>
  )
}

export default DefaultLayout
