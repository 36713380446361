

import React from 'react'
import "./style.css"

const EllipsisText = ({text , style, ...props}) => {


  return (
    <div className="ellisips_text"
    title={text}
      style={{
        
          ...style
      }}

      {...props}
    
    
    
    >
    {
      text
    }
  </div>
  )
}

export default EllipsisText
