import {useEffect} from "react";
import "./style.css";
import {  doubleRightArrow } from "../../assets/svgicons";

const SideBar = ({
  title,
  closeButton,
  direction,
  children,
  open,
  onClose,
  width,
  ...props
}) => {
  return (
    <>
      <div
        className={`drawer_overlay ${open ? "open" : ""}`}
        onClick={onClose}
      ></div>

      <div
        style={{...props.style, width: width ? width : "230px"}}
        id={"drawer"}
        className={`drawer ${open ? "open" : ""} ${direction ? direction : ""}`}
      >
        {closeButton ? (
          closeButton
        ) : (
          <div className={`drawer_colse_button`}  onClick={onClose}>
            {doubleRightArrow}
          </div>
        )}
        {title && <div className='drawer_title'>{title}</div>}
        <div className={`drawer_content ${title ? "withTitle" : ""}`}>
          {children}
        </div>
      </div>
    </>
  );
};

SideBar.Button = ({
  color,
  fontWeight,
  children,
  active,
  activeStyles,
  icon,
  open,
  ...props
}) => {
  return (
    <div className={`drawer_button ${open? "open" : "close"} ${active ? "active" : ""}`} {...props}>
      <div className={`btn_icon ${open? "open" : "close"}`}>{icon}</div>
      {
        open &&
      <div className='btn-text' style={{color, fontWeight}}>
        {children}
      </div>
      }
    </div>
  );
};

export default SideBar;
