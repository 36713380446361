




import React from 'react'
import {Routes, Route} from "react-router-dom";
import { routes } from './routesData';
import HomePage from '../pages/HomePage/HomaPage';
import Countries from '../pages/CountriesPage/Countries';

const AppRoutes = () => {
  return (
    <div>
     <Routes>
        {routes.map((item, index) => {
          return (
            <Route path={item.path} element={item.component} key={index} />
          );
        })}
        <Route path='*' element={<Countries />} />
      </Routes>

      
    </div>
  )
}

export default AppRoutes
