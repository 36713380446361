

import React from 'react'
import NotificationsList from './../../components/NotificationsPage/NotificationsList';

const Notifications = () => {
  return (
    <div className='notification_container'>
        <NotificationsList />
    </div>
  )
}

export default Notifications
