


import React from 'react'
import "./style.css"
import SearchInput from '../SearchInput/SearchInput'
import HoverDropdown from './../HoverDropdown/HoverDropdown';


import userPlaceHolder from "../../assets/images/userplaceholder.jpg"



const dropDownsLinks = [
  {
    text:'sign out',
    pathname:'/'
  }
]


const Header = () => {
  return (
    <div className='header_container'>
      <div className='header_left_sider'>
        <SearchInput />
      </div>
      <div className='header_right_side'>
      <HoverDropdown
      links={dropDownsLinks}
      title={
          <>
            <div className='header_user_image'>
              <img src={userPlaceHolder} alt="" />
            </div>
          </>
      }/>
      </div>
    </div>
  )
}

export default Header
