

import React from 'react'
import CountriesList from './../../components/CountriesPage/CountriesList/CountriesList';
import "./style.css"

const Countries = () => {
  return (
    <div className='countries_container '>
         <CountriesList />
    </div>
  )
}

export default Countries
