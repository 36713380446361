import React, {useEffect, useState} from "react";
import TableLayout from "./../../table/index";

import "./style.css";
import CustomButton from "./../../../CustomButton/CustomButton";
import {
  closeEye,
  DeleteIcon,
  EditIcon,
  flagPlus,
  opeEye,
  upDownIcon,
} from "../../../assets/svgicons";
import PopUp from "./../../popup/index";
import Modal from "./../../../Modal/Modal";
import CustomInput from "./../../CustomInput/CustomInput";
import FromGroup from "./../../FromGroup/FromGroup";
import SelectImage from "./../../SelectImage/SelectImage";
import toast from "react-hot-toast";
import {BASE_URL} from "./../../Axios/BaseUrl";
import axios from "axios";

const CountriesList = () => {
  const [newState, setNewState] = useState({
    country_name: "",
    base_url: "",
    order_no: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedEditFile, setSelectedEditFile] = useState(null);
  const [rowData, setRowData] = useState({});
  const [image, setImage] = useState("");
  const [editImage, setEditImage] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  
  const [modals, setModals] = useState({
    addModal: false,
    editModal: false,
    showHideModal: false,
  });

  const headers = [
    {
      label: "#",
      dataIndex: "country_id",
    },

    {
      label: "Flag",
      type: "children",
      children: ({row}) => {
        return (
          <div className='table_row_image'>
            <img src={row.image_url} alt='image' />
          </div>
        );
      },
    },
    {
      label: "Base URL",
      type: "children",
      children: ({row}) => {
        return <div className=''>{row.base_url}</div>;
      },
    },

    {
      label: "Country",
      type: "children",
      children: ({row}) => {
        return <div>{row.country_name}</div>;
      },
    },
    {
      label: "Order number",
      type: "children",
      children: ({row}) => {
        return (
          <div
            className=' d-flex align-items-center gap-3'
            style={{width: "100px"}}
          >
            <CustomInput
              style={{textAlign: "center"}}
              onFocus={() => setRowData(row)}
              value={
                rowData.country_id == row.country_id
                  ? rowData.order_no
                  : row.order_no
              }
              onChange={(e) =>
                setRowData({
                  ...rowData,
                  order_no: e.target.value,
                })
              }
              onBlur={() => handleUpdateRowOrder()}
            />
            <div className=''>{upDownIcon}</div>
          </div>
        );
      },
    },

    {
      label: "Actions",
      type: "children",
      children: ({row}) => {
        return (
          <div className='d-flex algin-items center gap-3'>
            <button
              className='btn btn-info btn-sm text-light'
              onClick={() => {
                setModals({
                  ...modals,
                  editModal: true,
                });
                console.log(row);
                setEditImage(row.image_url);
                setRowData(row);
              }}
            >
              {EditIcon}
            </button>
            {/* <button className='btn btn-danger btn-sm'>{DeleteIcon}</button> */}
            <CustomButton
              onClick={() => {
                setRowData(row);
                setModals({
                  ...modals,
                  showHideModal: true,
                });
              }}
              text={row.hidden == "no" ? "Hide" : "Show"}
              icon={row.hidden == "no" ? closeEye : opeEye}
              bgColor={row.hidden == "no" ? "#8a2209" : "#0e7eae"}
              color={"#fff"}
            />
          </div>
        );
      },
    },
  ];

  const getCountries = async () => {
    setAddLoading(true);
    await axios
      .get(`${BASE_URL}select_countries.php`)
      .then((res) => {
        if (res.data.status == "success") {
          setData(res?.data?.message);
          setOriginalData(res?.data?.message);
          console.log(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  const handleUpdateRowOrder = async () => {
    const dataset = {
      country_id: rowData.country_id,
      order_no: rowData.order_no,
    };

    await axios
      .post(`${BASE_URL}update_order_no.php`, dataset)
      .then((res) => {
        console.log(res);

        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          // getCategories();
          getCountries();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const handleChangeState = async (c_id, c_state) => {
    setAddLoading(true);

    const dataset = {
      country_id: c_id,
      status: c_state == "no" ? "yes" : "no",
    };

    await axios
      .post(`${BASE_URL}update_country_hidden.php`, dataset)
      .then((res) => {
        console.log(res);

        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          // getCategories();
          getCountries();
          setModals({
            ...modals,
            showHideModal: false,
          });
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const addNewCountry = async (imageLink) => {
    const dataset = {
      ...newState,
      image_url: imageLink.data,
    };

    console.log(dataset);

    await axios
      .post(`${BASE_URL}add_country.php`, dataset)
      .then((res) => {
        console.log(res);

        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          // getCategories();
          getCountries();
          setModals({
            ...modals,
            addModal: false,
          });
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setNewState({
          country_name: "",
          image_url: "",
          base_url: "",
          order_no: "",
        });

        setImage("");
        setSelectedFile(null);
      });
  };

  const handleAddFile = async () => {
    if (!newState.country_name) {
      toast.error("Write country name!");
      return;
    }

    // if (!newState.base_url) {
    //   toast.error("Write country base URL! ");
    //   return;
    // }

    // if (!img) {
    //   toast.error("لم تقم بإضافة صورة");
    //   return;
    // }

    setAddLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}image_uplouder.php`, formData)
      .then((res) => {
        console.log(res);
        if (res) {
          // setImgUrl(res?.data?.message);
          addNewCountry(res);
        } else if (res == "") {
          toast.error("There Are Problems");
        } else {
          toast.error("There Are Problem");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setAddLoading(false);
      });
  };

  const handleUpdateStateData = async (editImage) => {
    const dataset = {
      ...rowData,
      country_id: rowData.country_id,
      image_url: editImage,
    };

    console.log(dataset);
    // return

    await axios
      .post(`${BASE_URL}edit_country.php`, dataset)
      .then((res) => {
        console.log(res);

        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          // getCategories();
          getCountries();
          setModals({
            ...modals,
            editModal: false,
          });
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setNewState({
          country_name: "",
          image_url: "",
          base_url: "",
          order_no: "",
        });
        setImage("");
        setSelectedFile(null);
      });
  };

  const updateImage = async () => {
    if (!rowData.country_name) {
      toast.error("Country name is required!");
      return;
    }

    // if (!rowData.base_url) {
    //   toast.error("Country base URL is required!");
    //   return;
    // }

    if (!editImage && !selectedEditFile) {
      toast.error("Country image is required!");
      return;
    }

    // if (rowData.color == "") {
    //   toast.error(language=='ar'?" قم بإضافة لون الفئة أولاً":"Select Color First");
    //   return;
    // }

    setAddLoading(true);

    if (selectedEditFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedEditFile);
      await axios
        .post(`${BASE_URL}image_uplouder.php`, formData)
        .then((res) => {
          console.log(res);
          if (res && res.data) {
            handleUpdateStateData(res.data);
          } else if (res.data == "") {
            toast.error("There Are Problem");
          } else {
            toast.error("There Are Problem");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    } else {
      handleUpdateStateData(editImage);
    }

    setAddLoading(false);
  };

  
  return (
    <div className='page_list'>
      <div className='c_list_content'>
        <div className=''>
          <CustomButton
            onClick={() =>
              setModals({
                ...modals,
                addModal: true,
              })
            }
            icon={flagPlus}
            bgColor={"rgb(8, 87, 130)"}
            color={"#FFF"}
            text={"Add Country"}
          />
        </div>

        <TableLayout data={data} headers={headers} />
      </div>

      {/* Modals */}

      <Modal
        title={"Add Country"}
        show={modals.addModal}
        animation={true}
        overlay={true}
        showCloseBtn
        onClose={() =>
          setModals({
            ...modals,
            addModal: false,
          })
        }
        size={"50%"}
        confirmButton={{
          onClick: () => {
            handleAddFile();
          },
          children: <>Add</>,
        }}
        children={
          <>
            <div className='d-flex gap-3 w-100'>
              <FromGroup rowCount={1}>
                <FromGroup.Input
                  value={newState.country_name}
                  onChange={(e) =>
                    setNewState({
                      ...newState,
                      country_name: e.target.value,
                    })
                  }
                  placeholder='Country name'
                  label='Country name'
                  required
                />
                <FromGroup.Input
                  value={newState.base_url}
                  onChange={(e) =>
                    setNewState({
                      ...newState,
                      base_url: e.target.value,
                    })
                  }
                  placeholder='Country link'
                  label='Country link'
                  required
                />
                <FromGroup.Input
                  value={newState.order_no}
                  onChange={(e) =>
                    setNewState({
                      ...newState,
                      order_no: e.target.value,
                    })
                  }
                  placeholder='Order Number'
                  label='Order Number'
                  required
                />

                <SelectImage
                  id='add'
                  image={image}
                  setImage={setImage}
                  setSelectedFile={setSelectedFile}
                />
              </FromGroup>
            </div>
          </>
        }
      />
      <Modal
        title={"Edit Country"}
        show={modals.editModal}
        animation={true}
        overlay={true}
        showCloseBtn
        onClose={() =>
          setModals({
            ...modals,
            editModal: false,
          })
        }
        size={"50%"}
        confirmButton={{
          onClick: () => {
            updateImage();
          },
          children: <>Edit</>,
        }}
        children={
          <>
            <div className='d-flex gap-3 w-100'>
              <FromGroup rowCount={1}>
                <FromGroup.Input
                  value={rowData.country_name}
                  onChange={(e) =>
                    setRowData({
                      ...rowData,
                      country_name: e.target.value,
                    })
                  }
                  placeholder='Country name'
                  label='Country name'
                  required
                />
                <FromGroup.Input
                  value={rowData.base_url}
                  onChange={(e) =>
                    setRowData({
                      ...rowData,
                      base_url: e.target.value,
                    })
                  }
                  placeholder='Country link'
                  label='Country link'
                  required
                />
                <FromGroup.Input
                  value={rowData.order_no}
                  onChange={(e) =>
                    setRowData({
                      ...rowData,
                      order_no: e.target.value,
                    })
                  }
                  placeholder='Order Number'
                  label='Order Number'
                  required
                />

                <SelectImage
                  id='edit'
                  image={editImage}
                  setImage={setEditImage}
                  setSelectedFile={setSelectedEditFile}
                />
              </FromGroup>
            </div>
          </>
        }
      />
      <Modal
        title={"Change Country State"}
        show={modals.showHideModal}
        animation={true}
        overlay={true}
        showCloseBtn
        onClose={() =>
          setModals({
            ...modals,
            showHideModal: false,
          })
        }
        size={"50%"}
        confirmButton={{
          onClick: () => {
            handleChangeState(rowData.country_id, rowData.hidden);
          },
          children: <>{rowData.hidden == "no" ? "Hide" : "Show"}</>,
        }}
        cancelButton={{
          onClick: () => {
            setModals({
              ...modals,
              showHideModal: false,
            });
          },
          children: <>Cancel</>
          
        }}
        children={
          <>
            <div className='d-flex gap-3 w-100 my-3'>
              Are your sure about changing country state to be (
              <span className='text-primary fw-bolder'>
                {rowData.hidden == "no" ? "Hidden" : "Shown"}
              </span>
              )?
            </div>
          </>
        }
      />
    </div>
  );
};

export default CountriesList;
