


import React from 'react'
import "./style.css"
import { searchIcon, seasrchIcon_m } from '../../assets/svgicons'

const SearchInput = ({...props}) => {
  return (
    <div className='search_input_container'>
        <div className='search_input_icon'>
            <input {...props} type="text" />
            <div className='searchIcon lh-0'>{seasrchIcon_m}</div>
        </div>
    </div>
  )
}

export default SearchInput
