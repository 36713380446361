// NotificationsList.js

import { onValue, ref, set } from "firebase/database"; // for Realtime Database
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { add_notification, readMark } from "../../assets/svgicons";
import CustomButton from "../../CustomButton/CustomButton";
import { db, messaging } from "../../firebase"; // Import Firebase instances
import Modal from "../../Modal/Modal";
import FromGroup from "../FromGroup/FromGroup";
import TableLayout from "../table";
import EllipsisText from "./../EllipsisText/EllipsisText";
import { BASE_URL } from "../Axios/BaseUrl";
import axios from "axios";
import toast from "react-hot-toast";

const NotificationsList = () => {
  const [newNoti, setNewNoti] = useState({
    message: "",
    user: "",
  });
  const [originalData, setOriginalData] = useState([]);

  const [modals, setModals] = useState({
    addModal: false,
  });

  const [data, setData] = useState([]);

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Message",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            style={{
              width: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <EllipsisText
              style={{
                Width: "200px",
                height: "40px",
              }}
              text={row.body}
            />
          </div>
        );
      },
    },
    {
      label: "Country",
      type: "children",
      children: ({ row }) => {
        return <div className="">{row.country_name}</div>;
      },
    },
    {
      label: "Sent At",
      type: "children",
      children: ({ row }) => {
        return <div className="">{row.sent_at}</div>;
      },
    },
    {
      label: "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <p
            style={{
              color: "red",
              textDecoration: "under-line",
              cursor: "pointer",
            }}
            onClick={() => {
              try {
                axios
                  .post(`${BASE_URL}delete_notification.php`, {
                    id: row?.notification_id,
                  })
                  .then((res) => {
                    console.log(res);

                    toast.success(res.data?.message);

                    getNotifications();
                  })
                  .catch((e) => console.log(e))
                  .finally(() => {});
              } catch (error) {
                console.error("Error adding notification:", error);
              }
            }}
          >
            Delete
          </p>
        );
      },
    },
  ];

  // const users = [
  //   {
  //     id: 1,
  //     label: "Mohamed Elsayed",
  //     value: "Mohamed Elsayed",
  //   },
  //   {
  //     id: 2,
  //     label: "Ahmed Hassan",
  //     value: "Ahmed Hassan",
  //   },
  //   {
  //     id: 3,
  //     label: "Mustafa khaled",
  //     value: "Mustafa khaled",
  //   },
  // ];
  const getNotifications = () => {
    axios
      .get(`${BASE_URL}get_notifications.php`)
      .then((res) => {
        if (res.data.status == "success") {
          setData(res?.data?.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };
  useEffect(() => {
    getNotifications();
  }, []);

  // Function to add a new notification to Realtime Database
  const addNotification = async () => {
    try {
      await axios
        .post(`${BASE_URL}send_notification.php`, {
          title: "New Notification",
          body: newNoti?.message,
          user_id: newNoti?.user,
          country_id: newNoti?.country_id,
        })
        .then((res) => {
          console.log(res);

          toast.success(res.data);
          setModals({
            ...modals,
            addModal: false,
          });
          getNotifications();
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    } catch (error) {
      console.error("Error adding notification:", error);
    }
  };
  const getCountries = async () => {
    await axios
      .get(`${BASE_URL}select_countries.php`)
      .then((res) => {
        if (res.data.status == "success") {
          setOriginalData(
            res?.data?.message?.map((item) => ({
              value: item?.country_id,
              label: item?.country_name,
            }))
          );
          setNewNoti({
            ...newNoti,
            country_id: res?.data?.message[0]?.country_id,
          });
          console.log(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    getCountries();
  }, []);
  return (
    <div className="page_list">
      <div className="c_list_content">
        <div className="">
          <CustomButton
            onClick={() =>
              setModals({
                ...modals,
                addModal: true,
              })
            }
            icon={add_notification}
            bgColor={"rgb(8, 87, 130)"}
            color={"#FFF"}
            text={"Add Notification"}
          />
        </div>

        <TableLayout data={data ? data : []} headers={headers} />
      </div>

      <Modal
        title={"Add Notification"}
        show={modals.addModal}
        animation={true}
        overlay={true}
        showCloseBtn
        onClose={() =>
          setModals({
            ...modals,
            addModal: false,
          })
        }
        size={"50%"}
        confirmButton={{
          onClick: () => {
            addNotification();
          },
          children: <>{"Add"}</>,
        }}
        cancelButton={{
          onClick: () => {
            setModals({
              ...modals,
              addModal: false,
            });
          },
          children: <>Cancel</>,
        }}
        children={
          <>
            <div className="d-flex flex-column gap-3 w-100">
              <FromGroup rowCount={1}>
                <FromGroup.Input
                  value={newNoti.message}
                  onChange={(e) =>
                    setNewNoti({
                      ...newNoti,
                      message: e.target.value,
                    })
                  }
                  placeholder="Message"
                  label="Message"
                  required
                />
              </FromGroup>
              <Select
                placeholder="Assign To A Country"
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                name="countries"
                options={originalData}
                onChange={(selectedOption) =>
                  setNewNoti({
                    ...newNoti,
                    country_id: selectedOption.value,
                  })
                }
              />
            </div>
          </>
        }
      />
    </div>
  );
};

export default NotificationsList;
