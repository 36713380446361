// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getDatabase, ref, onValue } from "firebase/database"; // for Realtime Database

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCNamT7hUNJPPECrm07fMC-wi9raypAaNk",
  authDomain: "market-places-144f8.firebaseapp.com",
  databaseURL: "https://market-places-144f8-default-rtdb.firebaseio.com",
  projectId: "market-places-144f8",
  storageBucket: "market-places-144f8.appspot.com",
  messagingSenderId: "534552922519",
  appId: "1:534552922519:web:7ca79442d9e4946a029cd2",
  measurementId: "G-VHWQZZ7ZDN",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const db = getDatabase(app);

export { app, messaging, db };
