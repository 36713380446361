import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import "./bootstrap.min.css";
import { Loader } from "rsuite";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import UseGeneral from "../../customHooks/useGeneral";
import { AngleUp , AngleDown , chevronLeft , chevronRight} from './../../assets/svgicons/index';

const SCROLL_THRESHOLD = 100; // pixels from the edge to start scrolling
const SCROLL_SPEED = 10; // pixels per interval

const TableLayout = ({
  setSelectedRows_t,
  headers,
  data,
  finalDraggedRow,
  setFinalDraggedRow,
  draggedRow,
  setDraggedRow,
}) => {
  // const { language, changeLanguage } = UseGeneral();


  const language = "en"
  const navigate = useNavigate();
  const location = useLocation();
  const [paginatedData, setPaginatedData] = useState([]);
  const [originalData, setOriginalData] = useState(data);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [pageTwo, setPageTwo] = useSearchParams(
    { q: 1 },
    { replace: true, state: location?.state }
  );
  const [pagesNumber, setPagesNumber] = useState();
  const [searchQuery, setSearchQuery] = useState({});
  const [sortQuery, setSortQuery] = useState({});
  const [number, setNumber] = useState(10);

  const scrollInterval = useRef(null);

  useEffect(() => {
    setOriginalData(data);
  }, [data]);

  useEffect(() => {
    if (
      data &&
      data?.length &&
      Array?.isArray(data) &&
      originalData &&
      originalData?.length &&
      Array.isArray(originalData)
    ) {
      setPagesNumber(Math.ceil(originalData?.length / number));

      setPaginatedData(
        originalData?.filter(
          (item, index) =>
            index >= (parseInt(pageTwo.get("q")) - 1) * number &&
            index < parseInt(pageTwo.get("q")) * number
        )
      );
    } else {
      setPaginatedData([]);
    }
  }, [pageTwo, originalData, number, data]);

  useEffect(() => {
    if (!pageTwo.get("q")) {
      setPageTwo({ q: 1 }, { replace: true, state: location?.state });
    }
  }, [number, pageTwo, setPageTwo, location?.state]);

  useEffect(() => {
    searchFunction();
  }, [searchQuery]);

  useEffect(() => {
    if (sortQuery.type === "asc") {
      sortAscFunction();
    } else if (sortQuery.type === "dsc") {
      sortDscFunction();
    }
  }, [sortQuery]);

  const getPageButtons = () => {
    if (pagesNumber <= 5) {
      return Array.from({ length: pagesNumber }, (_, i) => i + 1);
    } else if (parseInt(pageTwo.get("q")) <= 3) {
      return [1, 2, 3, "...", pagesNumber];
    } else if (parseInt(pageTwo.get("q")) > pagesNumber - 2) {
      return [1, "...", pagesNumber - 2, pagesNumber - 1, pagesNumber];
    } else {
      return [
        1,
        "...",
        parseInt(pageTwo.get("q")) - 1,
        parseInt(pageTwo.get("q")),
        parseInt(pageTwo.get("q")) + 1,
        "...",
        pagesNumber,
      ];
    }
  };

  const searchFunction = () => {
    if (searchQuery?.value && searchQuery?.value?.length) {
      let arr = [];
      let uniqueItems = new Set();

      data?.forEach((item) => {
        if (
          item[searchQuery?.dataIndex] &&
          item[searchQuery?.dataIndex]?.length
        ) {
          if (searchQuery?.equal) {
            if (
              item[searchQuery?.dataIndex] === searchQuery?.value &&
              !uniqueItems.has(item)
            ) {
              arr.push(item);
              return uniqueItems.add(item);
            } else {
              setOriginalData([]);
            }
          } else {
            if (
              item[searchQuery?.dataIndex]?.includes(searchQuery?.value) &&
              !uniqueItems.has(item)
            ) {
              arr.push(item);
              return uniqueItems.add(item);
            }
          }
        } else {
          setOriginalData([]);
        }
      });
      setOriginalData([...arr]);
      if (arr?.length) {
        setPageTwo(
          {
            q: 1,
          },
          { replace: true, state: location?.state }
        );
      } else {
        setOriginalData([]);
      }
    } else {
      setOriginalData(data);
    }
  };

  const sortAscFunction = () => {
    if (sortQuery?.dataIndex && sortQuery?.dataIndex?.length) {
      setOriginalData((prevData) => {
        return [...prevData].sort((a, b) => {
          return !isNaN(a[sortQuery?.dataIndex]) &&
            !isNaN(b[sortQuery?.dataIndex])
            ? parseInt(b[sortQuery?.dataIndex]) -
                parseInt(a[sortQuery?.dataIndex])
            : b[sortQuery?.dataIndex]?.localeCompare(a[sortQuery?.dataIndex]);
        });
      });
    }
  };

  const sortDscFunction = () => {
    if (sortQuery?.dataIndex && sortQuery?.dataIndex?.length) {
      setOriginalData((prevData) => {
        return [...prevData].sort((a, b) => {
          return !isNaN(a[sortQuery?.dataIndex]) &&
            !isNaN(b[sortQuery?.dataIndex])
            ? parseInt(a[sortQuery?.dataIndex]) -
                parseInt(b[sortQuery?.dataIndex])
            : a[sortQuery?.dataIndex]?.localeCompare(b[sortQuery?.dataIndex]);
        });
      });
    }
  };

  const handleDragStart = (e, index, row) => {
    setDraggedRow && setDraggedRow(row); // Set the dragged row state to the actual row object
    e.dataTransfer.setData("text/plain", JSON.stringify(row)); // Store the row object as JSON in dataTransfer
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    const { clientY } = e;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (clientY < SCROLL_THRESHOLD && scrollTop > 0) {
      if (!scrollInterval.current) {
        scrollInterval.current = setInterval(() => {
          window.scrollBy(0, -SCROLL_SPEED);
        }, 10);
      }
    } else if (
      clientY > windowHeight - SCROLL_THRESHOLD &&
      scrollTop < documentHeight - windowHeight
    ) {
      if (!scrollInterval.current) {
        scrollInterval.current = setInterval(() => {
          window.scrollBy(0, SCROLL_SPEED);
        }, 10);
      }
    } else {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }
  };

  const handleDrop = (e, index, row) => {
    clearInterval(scrollInterval.current);
    scrollInterval.current = null;

    const draggedRow = JSON.parse(e.dataTransfer.getData("text/plain")); // Parse the row object from dataTransfer

    if (draggedRow !== null) {
      // Check if a valid row object is present
      const updatedData = [...paginatedData];
      const draggedRowIndex = updatedData.findIndex(
        (item) => item === draggedRow
      ); // Find the index of the dragged row

      if (draggedRowIndex !== -1 && draggedRowIndex !== index) {
        const draggedItem = updatedData[draggedRowIndex];
        updatedData.splice(draggedRowIndex, 1); // Remove the dragged row from its original position
        updatedData.splice(index, 0, draggedItem); // Insert the dragged row at the drop position
        setPaginatedData(updatedData); // Update the paginated data state
      }
    }

    setFinalDraggedRow(row); // Set final dragged row state to the dragged row object
  };

  const handleDragEnd = () => {
    clearInterval(scrollInterval.current);
    scrollInterval.current = null;
    setDraggedRow(null);
    setFinalDraggedRow(null);
  };

  const handleSelectRow = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.some((selectedRow) => selectedRow === row)) {
        return prevSelectedRows.filter((selectedRow) => selectedRow !== row);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  useEffect(() => {
    if (selectedRows && selectedRows?.length) {
      if (setSelectedRows_t) setSelectedRows_t(selectedRows);
    } else {
      if (setSelectedRows_t) setSelectedRows_t(null);
    }
  }, [selectedRows]);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows([...paginatedData]);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    // if (data and draggedRow) console.log("Row", data[draggedRow], "Final", data[finalDraggedRow]);
  }, [draggedRow]);

  return (
    <>
      <div className="table">
        <table>
          <thead className="tableRow">
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              {headers.map((header, index) =>
                header?.label && header?.label?.length ? (
                  <th className="tableHeader" key={index}>
                    <span
                      style={{ cursor: header?.sort ? "pointer" : "initial" }}
                      className="filters"
                    >
                      <span>{header?.label}</span>
                    </span>
                    <div className="sorts">
                      {header?.search ? (
                        <input
                          type="search"
                          onKeyUp={(e) => {
                            setSearchQuery({
                              ...searchQuery,
                              dataIndex: header.dataIndex,
                              value: e.target.value,
                              equal: header?.equal,
                            });
                          }}
                          onChange={(e) => {
                            setSearchQuery({
                              ...searchQuery,
                              dataIndex: header.dataIndex,
                              value: e.target.value,
                              equal: header?.equal,
                            });
                          }}
                        />
                      ) : null}
                      <span
                        onClick={() => {
                          if (header?.sort) {
                            if (header.asc) {
                              setSortQuery({
                                dataIndex: header?.dataIndex,
                                type: "dsc",
                              });
                              header.asc = false;
                            } else {
                              setSortQuery({
                                dataIndex: header?.dataIndex,
                                type: "asc",
                              });
                              header.asc = true;
                            }
                          }
                        }}
                      >
                        {header?.asc && header?.sort ? (
                          <AngleDown />
                        ) : !header?.asc && header?.sort ? (
                          <AngleUp />
                        ) : null}
                      </span>
                    </div>
                  </th>
                ) : null
              )}
            </tr>
          </thead>
          <tbody className="tableRow tableBody">
            {!data ? (
              <Loader size="lg" />
            ) : data?.length && Array?.isArray(data) ? (
              paginatedData?.map((row, index) => (
                <tr
                  className="body"
                  key={index}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index, row)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index, row)}
                  onDragEnd={handleDragEnd}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow === row
                      )}
                      onChange={() => handleSelectRow(row)}
                    />
                  </td>
                  {headers?.map((column, columnIndex) => {
                    const dataIndex = column?.dataIndex;
                    const cellData = row[dataIndex];
                    if (column && column?.label && column?.label?.length)
                      return (
                        <td className="tableCell" key={columnIndex}>
                          {column?.type === "children" ? (
                            <>
                              {column?.children({
                                headers: column,
                                row: row,
                                index: index,
                                lastIndex: paginatedData?.length,
                              })}
                            </>
                          ) : (
                            cellData
                          )}
                        </td>
                      );
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length + 1}>
                  <h4>
                    {language == "ar"
                      ? "لا يوجد بيانات"
                      : "There Are No Emails"}
                  </h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <hr />
      </div>
      <div className="pagination">
        <div>
          <p>
            {language == "ar"
              ? "عدد العناصر بالصفحة:"
              : "Count Of Elements In Page"}
          </p>
          <select
            name="rowNumbers"
            id="rowNumbers"
            value={number}
            onChange={(e) => setNumber(Number(e.target.value))}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
            <option value="250">250</option>
            <option value="300">300</option>
            <option value="350">350</option>
            <option value="400">400</option>
            <option value="450">450</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>
        {data && data?.length && Array?.isArray(data) ? (
          <p>
            <button
              className="btn"
              onClick={() =>
                setPageTwo(
                  {
                    q:
                      pageTwo.get("q") > 1
                        ? parseInt(pageTwo.get("q")) - 1
                        : pageTwo.get("q"),
                  },
                  { replace: true, state: location?.state }
                )
              }
            >
              {chevronRight}
            </button>

            {getPageButtons().map((button, index) => (
              <button
                className={
                  button == parseInt(pageTwo.get("q"))
                    ? "btn btn-primary paginated"
                    : "btn paginated"
                }
                key={index}
                onClick={() =>
                  button == "..."
                    ? null
                    : setPageTwo(
                        {
                          q: button,
                        },
                        { replace: true, state: location?.state }
                      )
                }
              >
                {button}
              </button>
            ))}

            <button
              className="btn"
              onClick={() => {
                setPageTwo(
                  {
                    q:
                      pageTwo.get("q") < pagesNumber
                        ? parseInt(pageTwo.get("q")) + 1
                        : pageTwo.get("q"),
                  },
                  { replace: true, state: location?.state }
                );
              }}
            >
              {chevronLeft}
            </button>
          </p>
        ) : null}
      </div>
    </>
  );
};

export default TableLayout;
