import Countries from '../pages/CountriesPage/Countries';
import Notifications from '../pages/Notifications/Notifications';
import HomePage from './../pages/HomePage/HomaPage';






export const routes = [
  {
    path:'/',
    component: <Countries />,
    name:"Countries"
  },
  {
    path:'/notifications',
    component: <Notifications />,
    name:"Notifications"
  }
]