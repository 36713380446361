import React, {useState, useEffect} from 'react'
import { exitModal, imagePlaceholder } from './svgicon'

import "./style.css"

const SelectImage = ({ id ,  image, setImage , style , setSelectedFile}) => {
  const [readerResult, setReaderResult] = useState()
  const [file, setFile] = useState(null)
  // const [, setBase64Image] = useState('')

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader()

      reader.onloadend = () => {
        setReaderResult(reader.result)
        setFile(selectedFile)
      }
      reader.readAsDataURL(selectedFile)
    }
  }

  useEffect(() => {
    setImage(readerResult)
  }, [readerResult])


  useEffect(()=>{
    if(!image){
      setFile(null)
    }
  }, [image])

  return (
    <div>
      {!image && (
        <div className="d-flex">
          <label htmlFor={id} className="imageIcon" >
            {imagePlaceholder}
          </label>
        </div>
      )}
      <input multiple className="inputFile" type="file" name="" id={id} onChange={handleFileChange} />

      {image && (
        <div className="displayedImage">
          <div className="image_container">
            <div className="closeButton" onClick={() => setImage('')}>
              {exitModal}
            </div>
            <img  src={image}  style={{...style}}/>
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectImage
